<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.adTitle"
              placeholder="广告名称"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
      <a-button type="primary" @click="add" style="margin-left:20px;" icon="plus">
        新增
      </a-button>
      <div style="margin-left:20px;color:#FF9900;">配置在诚信点评小程序中展示的横幅广告（多个配置，在帖子列表中会交叉展示，在帖子详情页面会随机展示一个）</div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('validStatus', 'color')" style="cursor: pointer">
          {{ record.status| dictName("validStatus") }}
        </a-tag>
      </template>
      <template slot="adType" slot-scope="text, record">
        <a-tag :color="text | dictName('bannerAdType', 'color')" style="cursor: pointer">
          {{ text| dictName("bannerAdType") }}
        </a-tag>
      </template>
      <template slot="image" slot-scope="text, record">
        <div v-if="record.image" class="img-list">
          <el-image
              style="width: 150px; height: 80px"
              :src="(record.image || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.image || '').split(',')"
          />
        </div>
      </template>
      <template slot="timeScope" slot-scope="text, record">
        {{ record.beginTime || '~' }} 至 {{ record.endTime || '~' }}
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" icon="edit" size="small" @click="modify(record)">
          修改
        </a-button>
        <a-button type="danger" icon="edit" size="small" @click="delOne(record)" style="margin-left: 10px;">
          删除
        </a-button>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyAdConfigModal";

export default {
  name: "AdConfigList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "adConfig/list",
      listMethod: "get",
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },

        {
          title: "广告名称",
          align: "center",
          width: 250,
          dataIndex: "adTitle",
        },
        {
          title: "广告类型",
          align: "center",
          width: 120,
          dataIndex: "type",
          scopedSlots: {customRender: "adType"},
        },
        {
          title: "状态",
          align: "center",
          width: 80,
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "广告图片",
          align: "center",
          width: 150,
          dataIndex: "image",
          scopedSlots: {customRender: "image"},
        },
        {
          title: "广告时间",
          align: "center",
          width: 150,
          scopedSlots: {customRender: "timeScope"},
        },
        {
          title: "页面配置",
          align: "center",
          width: 200,
          ellipsis: true,
          dataIndex: "configJson",
        },
        {
          title: "备注说明",
          align: "center",
          width: 200,
          dataIndex: "remark",
        },
        {
          title: "操作",
          width: 180,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    add() {
      this.$refs.modifyModal.showModal(undefined, 'new');
    },
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },
    delOne(record){

      let that = this;

      this.$confirm({
        title: "删除确认",
        content: "您确定要删除广告【" + record.adTitle + "】吗？！",
        centered: true,
        onOk() {
          that.$postJson("adConfig/del", [record.id]).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    }
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
